.alert-modal-confirm-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .alert-modal-confirm {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 90%;
  }
  
  .alert-confirm-icon {
    color: #f00;
    font-size: 2em;
  }
  
  .alert-confirm-message {
    margin: 15px 0;
  }
  
  .alert-confirm-button {
    background: #007bff;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin-right: 10px;
  }
  
  .alert-confirm-button:hover {
    background: #0056b3;
  }


  @media (min-width: 992px) {
    .alert-modal-confirm {
      max-width: 450px;
    }
  }
  