.login-page {
  background-image: var(--background-image-mobile);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100vw;
}

@media (min-width: 768px) {
  .login-page {
    background-image: var(--background-image);
  }
}

.signup-login {
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-right: 1px solid white;
  width: 100%;
  max-width: 484px;
  margin: 0;
  flex: 1;
}

.signup-logotipo {
  display: flex;
  width: 290px;
  height: auto;
  padding: 44px 4px 44px 4px;
  justify-content: center;
  align-items: center;
}

.logomarca {
  width: 250px;
  height: auto;
}

.signup-logomarca {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.google-play-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
  margin-right: -50px
}

.signup-form {
  padding: 20px;
  margin: 0;

}
@media (min-width: 768px) {
  .signup-form {
    margin: 5px;
  }
}

.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-login, .button-forgot {
  margin-bottom: 20px;
  width: 100%;
  max-width: 200px;
  background-color: #ff523a;
  border: none;
}

.button-signup {
  width: 100%;
  max-width: 200px;
  background-color: #000;
  border: none;
}

.button-signup-cadastro {
  width: 100%;
  max-width: 200px;
  background-color: #000;
  border: none;
  margin-bottom: 20px;
}

.button-signup-cadastro-back {
  width: 100%;
  max-width: 200px;
  background-color: #000;
  border: none;
}

.button-login:hover, .button-signup-cadastro-back:hover {
  background-color: #ff523a;
  border: none;
}

.button-signup:hover, .button-signup-cadastro:hover {
  background-color: #ff523a;
  border: none;
}

.form-signup {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  background-color: #ffffff28;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-group {
  position: relative;
}

.input-group-text {
  position: absolute;
  right: 5px;
  top: 5px;
  /*transform: translateY(-50%);*/
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 99;
}

.user-icon {
  cursor: auto;
}

.form-control {
  padding-right: 30px; /* Adicione um espaço para o ícone */
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid black;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
  border-color: black;
  background-color: transparent;
}

/* .recaptcha-container div div {
  width: 100% !important;
  margin-bottom: 10px !important;
  border-radius: 8px !important;
}

.recaptcha-container div div div iframe {
  width: 100% !important;
} */
