body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./images/home/background-default-mobile.gif);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 768px) {
    body {
      background: url(./images/home/background-default.gif);
    }
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #5c5c5c;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid transparent;  /* creates padding around scroll thumb */
}