.payment-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 140px;
  width: 140px;
  justify-content: space-around;
}

.payment-card p {
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 0;
  
}

.payment-card-header h5{
  font-weight: bold;
}

/* Estilo específico para os ícones */
.payment-card svg {
  font-size: 34px;
}

.monthly-cards-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 992px;
  gap: 14px;
  cursor: pointer;
  justify-content: center;
}

.monthly-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px;
}
