.payment-title {
    color: #000;
    text-align: center;
    width: 100%;
    font-weight: bold;
}

.payment-subtitle {
    font-size: 2rem;
    color: #000;
    margin-top: 0.75rem;
    font-weight: bold;

}

.form-box-pix {
    background-color: #ffffff;
}

.payments-container {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.payments-types {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    gap: 1.5rem;
}

.payment-types-img {
    background-color: #838080;
    border-radius: 8px;
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.text-type-button {
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    width: 90%;
    margin: 0;
}

.payment-description {
    color: #000;
    text-align: justify;
}

.payment-userdata{
    color: blue;
}

.payment-buttons-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    gap: 10rem;
    margin-top: 20px;
}


@media (min-width: 768px) {
    .form-box-pix {
       width: 100%;
    }

    .payments-types {
        flex-direction: row;
    }

    .payment-buttons-footer {
        flex-direction: row;
    }
}


@media (max-width: 768px) {
    .form-box-pix {
       width: 90%;
    }
}