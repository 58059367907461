.adm-header {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
}

.adm-header-container {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #ffffff28;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.header-name {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0;
}

.header-img {
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.header-text {
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
}

.header-text-desc {
    color: #ffffff;
    font-weight: normal;
    font-style: italic;
}

.header-img-container {
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-texts-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-top: 20px;
}

.header-img-brand_mobile {
    display: flex;
    width: 250px; 
    height: auto; 
    margin-bottom: 20px;
}

.header-img-brand{
    display: none;
}

.first-access {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (min-width: 992px) {
    .adm-header-container {
        flex-direction: row;
        padding: 10px 80px;
        justify-content: space-between;
    }

    .header-text {
        font-size: 24px;
    }

    .header-texts-container {
        padding-left: 50px;
        padding-top: 10px;
    }

    .header-img-brand_mobile {
        display: none;
    }

    .header-img-brand {
        display: flex;
        width: 350px; 
        height: auto; 
        margin-bottom: 20px;
    }
}
