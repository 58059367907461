.container-schedules {
  width: 100%;
}

.card-container-schedules {
  display: flex;
  width: 90%;
}

.card-total-schedules {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 98%;
  gap: 2;
}

.total-results-schedules {
  align-items: center;
  justify-content: center;
}

.card {
  background-color: #00FA9A;
}

.card-disabled {
  background-color: #DFE3EE;
}

.card:hover {
  background-color: #4682B4;
  cursor: pointer;
  color: #fff;
}

.card-disabled:hover {
  background-color: #FAAD9C;
  cursor: not-allowed;
  color: #fff;
}

.card-text {
  margin-bottom: .2rem;
  font-style: italic;
}

.card-body {
  color: #000;
}

.card-body:hover {
  color: #fff;
}

.card-subtitle {
  width: 100%;
  text-align: center;
}

.card-buttons-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.card-button {
  /*z-index: 1000;*/
  font-size: 1.2rem;
  color: blue;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  width: 100px;
}

.card-button:hover {
  color: #fff;
}

.modal-header-title {
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

.modal-body {
  color: #000;
  text-align: justify;
  padding-bottom: 1rem;
  font-size: 1rem;
}

.modal-body-container {
  background-color: transparent;
  width: 100%;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.modal-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.modal-form-group input {
  border-radius: 4px;
  border: 1px solid #5c5c5c;
  padding: 0 10px;
  height: 30px;
}

.modal-form-group select {
  border-radius: 4px;
  border: 1px solid #5c5c5c;
  padding: 0 10px;
  height: 30px;
}


@media (min-width: 768px) {
  .card-container-schedules {
    width: 85%;
  }

  .card-total-schedules {
    width: 100%;
  }
}