.card-container {
  width: 80%;
}

.adm-input-field {
  border-radius: 8px;
  padding: 0.75rem;
  font-size: 0.875rem;
  letter-spacing: 0.2rem;
  width: 500px;
  border: 2px solid #5c5c5c;
  background-color: transparent;
  box-shadow: none;
  color: #000;
  font-weight: bold;
  margin-right: 14px;
}

.adm-input-field-selected {
  width: 100px;
}

.adm-button {
  font-weight: bold;
  margin-right: 14px;
  padding: 0.75rem;
}

.adm-input-field:focus {
  box-shadow: none;
  color: #000;
  border: none;
  font-weight: bold;
  background-color: transparent;
}

.payments {
  display: grid;
  grid-template-columns: repeat(
    3,
    1fr
  ); /* Três colunas para organizar em linhas de três */
  gap: 10px; /* Espaçamento entre os itens */
  margin: 0 10%;
  width: 100%;
}

.payment-item {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #5c5c5c; /* Borda ao redor de cada item */
  padding: 10px;
  border-radius: 8px; /* Borda arredondada */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
}

.payment-item label {
  margin-bottom: 5px;
}

/* Adicione estilos para a tabela */
.table-container {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .table-container table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-container th, .table-container td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 12px;
  }
  
  .table-container th {
    background-color: #4CAF50;
    color: white;
  }
  
  /* Estilo alternado para linhas */
  .table-container tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Estilo para o cabeçalho da tabela dentro do bloco .result-card */
  .result-card .table-container th {
    background-color: #007bff;
  }
  
  /* Estilo para a tabela dentro do bloco .result-card */
  .result-card .table-container tr:nth-child(even) {
    background-color: #dae5f4;
  }
  

@media (max-width: 992px) {
  .card-container {
    width: 90%;
  }

  .adm-input-field,
  .adm-input-field-selected {
    width: 100%;
    margin-bottom: 20px;
  }

  .payments {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0;
}
}
