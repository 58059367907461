.adms-page-create {
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; */
  width: 100%;
  height: 100%;
  /*padding: 20px;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.adms-page-create h1 {
  text-align: center;
}


.card-container-users {
  width: 80%;
}

.new-users {
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.new-courses {
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.eye-button {
  margin-right: 20px;
}


/* Adicione estilos para a tabela */
.table-container-adms {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 40px;
  background-color: #fff;
  overflow-x: auto;
}

.table-container-adms table {
  width: 100%;
  border-collapse: collapse;
}

.table-container-adms th, .table-container-adms td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 12px;
}

.table-container-adms th {
  background-color: #4CAF50;
  color: white;
}

/* Estilo alternado para linhas */
.table-container-adms tr:nth-child(even) {
  background-color: #f2f2f2;
}
