.students-page {
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; */
  width: 100%;
  height: 100%;
  /*padding: 20px;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.students-page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;
  flex-wrap: wrap;
  margin-top: 50px;
  padding: 20px 10px;
}

.students-page-payment {
  width: 100%;
  height: 100%;
  /*padding: 20px;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.payments-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

@media (max-width: 992px) {
  .payments-content {
    margin-bottom: 2rem;
  }

}

.img-construct {
  width: 80%;
}


.folder-button {
  width: 198px;
  height: 54px;
  margin: 0 15px 15px 0;
  padding: 12px;

  gap: 12px;
  border-radius: 10px;
  border: 1px solid #FFFFFF;

  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  background-color: #010B49;
  transition: background-color 0.3s;
}

.folder-button:hover {
  background-color: #8B91B4;
}

.folder-open {
  background-color: #6574CB;
}

.folder-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  text-aling: flex-start;
  width: 100%;
}


.material-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;
  gap: 20px;
}

.material-container-contents {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  font-size: 60px;
  color: #007b9c;
  justify-content: flex-start;
}