.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: .5rem;
    background-color: #fff;
  }
  
  h1 {
    color: #333;
  }
  
  .input_file {
    margin: 10px 0;
  }
  
  .button_storage {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button_storage:hover {
    background-color: #45a049;
  },

  .container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .lista-de-arquivos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Espaçamento entre os itens */
  }
  
  .arquivo {
    flex-basis: calc(33.33% - 20px); /* Ajuste conforme necessário para o número desejado de colunas */
    box-sizing: border-box;
    border: 1px solid #ddd; /* Cor da borda */
    padding: 10px;
    text-align: center;
  }
  
  .arquivo span {
    display: block;
    font-weight: bold;
  }
  
  .arquivo a {
    text-decoration: none;
    color: #333; /* Cor do texto */
    display: block;
    margin-top: 5px;
  }
  
  .arquivo button {
    margin-top: 5px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
    color: #ff0000; /* Cor do botão de exclusão */
  }
  