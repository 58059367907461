.adms-page {
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; */
  width: 100%;
  height: 100%;
  /*padding: 20px;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.adms-page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;
  flex-wrap: wrap;
  margin-top: 50px;
  padding: 20px 10px;
  max-width: 80%;
}

.adms-page-payment {
  width: 100%;
  height: 100%;
  /*padding: 20px;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.adms-payments-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.card-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
}

.result-card {
  margin-top: 20px;
}

@media (max-width: 992px) {
  .adms-payments-content {
    margin-bottom: 2rem;
  }

  .adm-page-container {
    max-width: none;
  }
}