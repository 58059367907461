/* Estilos para o container */
.form-container_student {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  width: 100vw;
  max-width: 992px;
  flex: 1;
}

/* Estilos para o formulário */

.form-box-student {
  margin: auto;
  padding: 30px;
  background-color: #ffffff28;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

/* Estilos para os campos de entrada */
.input-container {
  font-size: 0.875rem;
  letter-spacing: 0.2rem;
  width: 100%;
}

.input-container_small {
  font-size: 0.875rem;
  letter-spacing: 0.2rem;
  width: 100%;
  max-width: 200px;
}

.input-container_small2 {
  font-size: 0.875rem;
  letter-spacing: 0.2rem;
  width: 100%;
  max-width: 260px;
}

.input-container_half {
  font-size: 0.875rem;
  letter-spacing: 0.2rem;
  width: 50%;
}

.input-container_half-select {
  font-size: 0.875rem;
  letter-spacing: 0.2rem;
  width: auto;
}

.input-label {
  color: #fff;
  font-weight: bold;
}

.input-field {
  border-radius: 8px;
  padding: 0.75rem;
  font-size: 0.875rem;
  letter-spacing: 0.2rem;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  font-weight: bold;
}

.input-field:focus {
  box-shadow: none;
  color: #fff;
  border: none;
  background-color: transparent;
}

.input-field:disabled {
  background-color: #c5c5c558;
}

.input-label {
  display: none;
}

.input-field::placeholder {
  color: #fff;
  font-weight: bold;
}


/* Estilos para o botão */
.form-buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}


.submit-button {
  border-radius: 0.25rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.2rem;
  color: #ffffff;
  transition: background-color 0.3s;
  width: 50%
}

.submit-button-cancelar {
  border-radius: 0.25rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.2rem;
  color: #ffffff;
  transition: background-color 0.3s;
  width: 50%;
}

.submit-button:hover, .submit-button-cancelar:hover {
  background-color: #000;
}

.submit-button:disabled {
  opacity: 0.5;
}

@media (max-width: 992px) {
  .form-box-student {
    width: 90%;
  }

  .input-container_half, .input-container_small, .input-container_small2, .input-container, .input-container_half-select {
    width: 100%;
    max-width: none;
  }

  .input-label {
    display: flex;
    width: 100%;
  }

  .input-field::placeholder {
    display: none;
  }

  .form-buttons-container {
    flex-direction: column;
    gap: 1rem;
  }

  .submit-button {
    width: 100%;
    font-size: 12px;
    height: auto;
  }
  
  .submit-button-cancelar {
    width: 100%;
    font-size: 12px;
  }
}
