.qr-code {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: #000;
}

.button-copy {
    width: 100%;
}

